import * as S from './styles';
import { ptBR } from 'date-fns/locale';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ClientProps, ProductProps } from '@/types';
import { useDbFunctions, useOrderStatusPage } from '@/talons';
import { OrderProps } from '@/talons/orderStatusPage/useOrderStatusPage';
import { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { OrderStatusCalendar as Calendar, OrderStatusFullCard as Card, Loading, Header } from '@/components';

interface ParsingDateProps {
  dia: number;
  mes: number;
  ano: number;
}

const OrderStatusPage = () => {
  const { ordersList, offlineOrdersList, synchedOrdersList, loading, ordersDate, filterDate, setFilterDate } = useOrderStatusPage();

  const [productsDict, setProductsDict] = useState({} as Record<number, ProductProps>);
  const [openSearch, setOpenSearch] = useState(false);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [filter, setFilter] = useState('');
  const [allClients, setClientList] = useState({} as Record<string, ClientProps>);

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { getOnDB } = useDbFunctions();

  const loadProductDetails = useCallback(async () => {
    const dict = await getOnDB("dicionario_produtos");
    setProductsDict(dict);
  }, [getOnDB]);

  useEffect(() => {
    if (!productsDict) {
      loadProductDetails();
    }
  }, [loadProductDetails, productsDict]);

  const loadClients = useCallback(async () => {
    const client = await getOnDB("clientes").then(clients => {
      return clients.reduce((acc, client) => ({ ...acc, [`${client.COD_CLIENTE}`]: client } ), {})
    });
    setClientList(client as Record<string, ClientProps>);
  }, [getOnDB]);

  useEffect(() => {
    if (!Object.keys(allClients || {}).length) {
      loadClients();
    }
  }, [loadClients, allClients]);

  const parsingDates = (dateStr: string): ParsingDateProps => {
    if (!dateStr) {
      console.error('Invalid date string:', dateStr);
      return {
        dia: 0,
        mes: 0,
        ano: 0,
      }
    }

    const [day, date, month, year] = dateStr.split(/[ ,/]+/);

    return {
      dia: Number(date),
      mes: Number(month),
      ano: Number(year),
    }

  };

  const mostRecentDate = useMemo(() => {
    if (ordersDate && ordersDate.length > 0) {
      return new Date(Math.max(...ordersDate.map((date: any) => date.getTime())));
    }
    return new Date(); // Retorna a data atual se `orderDates` estiver vazio
  }, [ordersDate]);

  // Unir e ordenar os pedidos
  const groupByDate = (orders: OrderProps[]) => {
    return orders?.reduce((groups, order) => {
      const date = order.orders.orderDate;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(order);

      return groups;
    }, {} as Record<string, OrderProps[]>);
  };

  const combinedOrdersList: any[] = [...(ordersList || []), ...(offlineOrdersList || []), ...(synchedOrdersList || [])];

  const groupedOrders = groupByDate(combinedOrdersList);

  const sortedGroupedOrders = Object.keys(groupedOrders)
    .sort((a, b) => {

      const dateA = parsingDates(a);
      const dateB = parsingDates(b);

      const dateStrA = `${dateA?.ano}-${dateA?.mes}-${dateA?.dia}`;
      const dateStrB = `${dateB?.ano}-${dateB?.mes}-${dateB?.dia}`;

      const parseB = new Date(dateStrB)?.getTime();
      const parseA = new Date(dateStrA)?.getTime();

      return parseB - parseA;
    })
    .map(date => {
      const ordersMap = new Map() as Record<string, any>;

      const clientList = [] as string[];

      const list = groupedOrders[date];
      list.forEach(i => {
        if (!ordersMap?.has(i.clientRefNumber)) {
          ordersMap.set(i.clientRefNumber, {
            clientName: i.clientName,
            clientRefNumber: i.clientRefNumber,
            clientCnpj: i?.clientCnpj,
            orders: [{ ...i.orders }],
          });
          clientList.push(i.clientRefNumber);
        } else if (ordersMap?.has(i.clientRefNumber)) {
          const val = ordersMap.get(i.clientRefNumber);
          const updatedVal = [
            ...val?.orders,
            {
              ...i.orders,
            }
          ];
          ordersMap.set(i.clientRefNumber, {
            ...val,
            orders: updatedVal,
          });
        }
      })

      return {
        date,
        ordersMap,
        clientList,
      }
    })

  const handleClearDate = () => {
    setFilterDate(undefined); // Desmarca a data
  };

  if (loading || !Object.keys(allClients || {}).length) {
    return (
      <S.PageContainer>
        <Header rightIcon={<S.SearchContent />} />

        <S.LoadingContainer>
          <Loading scale={1.5} />
        </S.LoadingContainer>
      </S.PageContainer>
    )
  }

  return (
    <S.PageContainer>
      {!openSearch ? (
        <Header
          rightIcon={
            <S.SearchContent>
              <S.SearchIcon size={20} data-test="my-clients-button-search" onClick={() => setOpenSearch(true)} />
            </S.SearchContent>
          }
        />
      ) : (
        <S.SearchHeader>
          <S.SearchInputWrapper>
            <S.SearchIcon size={20} data-test="my-clients-input-search" $isgray />
            <S.SearchInput
              ref={searchInputRef}
              type="text"
              placeholder="Busque por pedido..."
              onChange={(event: any) => setFilter(event.target.value.trim())}
            />
          </S.SearchInputWrapper>
          <S.CloseSearch onClick={() => setOpenSearch(false)}>Cancelar</S.CloseSearch>
        </S.SearchHeader>
      )}
      <S.FilterHeader>
        <S.FilterButton onClick={() => setShowDateFilter(!showDateFilter)} key="filterDateButton" data-test="pedido-filter-date-button">
          <S.FilterIcon />
          Filtrar
        </S.FilterButton>
        {filterDate && (
          <S.ClearFilter onClick={() => handleClearDate()}>
            Remover filtro
          </S.ClearFilter>
        )}
      </S.FilterHeader>
      {showDateFilter && (
        <S.CollapsibleFilterBox isVisible={true}>
          <DatePicker
            selected={filterDate}
            onChange={(date: any) => setFilterDate(date)}
            highlightDates={ordersDate}
            openToDate={mostRecentDate}
            inline
            placeholderText="Selecione uma data"
            isClearable
            showYearDropdown
            locale={ptBR}
          />
        </S.CollapsibleFilterBox>
      )}

      {sortedGroupedOrders.map(({ date, ordersMap, clientList }, groupIndex) => {
        return (<S.PageContent key={`group-${groupIndex}`}>
          {date !== undefined && !filter && (
            <Calendar date={date} pendingStatus={groupIndex > 0} />
          )}
          {clientList?.map((clientNumber, idx) => {
            const mapOrder = ordersMap.get(clientNumber);
            const clientName = mapOrder?.clientName;
            const clientCnpj = mapOrder?.clientCnpj;
            let ordersList = mapOrder?.orders;

            if (filter) {
              ordersList = ordersList.filter((order: any) => order?.orderNumber?.includes(filter));
            }

            if (mapOrder && ordersList.length > 0) return (
              <Card
                productsDict={productsDict}
                clientName={clientName}
                client={allClients[clientNumber] || {}}
                clientCnpj={clientCnpj}
                date={date}
                clientRefNumber={clientNumber}
                orders={ordersList}
                key={`card-${groupIndex}-${idx}`}
              />
            )
            return null;
          }
          )}
        </S.PageContent>
        )
      })}
    </S.PageContainer>
  )
}

export default OrderStatusPage;
