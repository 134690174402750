import { colors } from "@/constants";
import { Directions } from "@/types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { RiEdit2Line } from "react-icons/ri";

export const ContentInfos = styled.div<{ direction?: Directions }>`
  display: flex;
  flex-direction: ${(i) =>
    i.direction === "grid" ? "column" : "column-reverse"};
`;

export const ProductLink = styled(Link)`
  text-decoration: none;
`;

export const Div = styled.div`
  width: 100%;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
`;

export const EditIcon = styled(RiEdit2Line)``;

export const PriceContent = styled.div`
  gap: 10px;
  width: 100%;
  margin: 5px 0px;
  align-items: start;
  display: inline-flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  padding-bottom: 30px;
`;

export const ColPrice = styled.div`
  padding-bottom: 30px;
`;

export const ButtonFlex = styled.button<{ $isdisabled?: boolean }>`
  gap: 4px;
  border: none;
  display: flex;
  font-size: 12px;
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 8px 12px 8px 12px;
  color: ${(i) => (i.$isdisabled ? colors.gray.gray30 : colors.brand.white)};
  /* opacity: ${(i) => (i.$isdisabled ? 0.3 : 1)}; */
  background-color: ${(i) =>
    i.$isdisabled ? colors.gray.gray10 : colors.brand.grafite};
`;

export const ProductCard = styled.div`
  gap: 1rem;
  height: 100%;
  mask-border: 20px;
  border-radius: 10px;
  display: flex;
  /* overflow: hidden; */
  position: relative;
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: ${colors.brand.white};
  box-shadow: ${colors.shadown1} 0px 0px 8px;
`;

export const ProductContent = styled.div<{ direction?: Directions }>`
  flex: 1;
  display: flex;
  transition: all 0.25s;
  flex-direction: column;
  width: -webkit-fill-available;
  justify-content: space-between;
  padding: ${(i) => (i.direction === "grid" ? "10px" : "20px")};
`;

export const ProductImage = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
`;

export const PaymentCondition = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: fit-content;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${colors.grey10};

  font-size: 11px;
  font-weight: 600;
  line-height: 12.89px;
  color: ${colors.gray.gray50};

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const BtnAdd = styled.button`
  gap: 4px;
  width: 100%;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  padding: 10px 25px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: ${colors.brand.white};
  background: ${colors.brand.red};
`;

export const BtnAddList = styled.button<{ grey?: number, hasExpiring?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: none;
  display: grid;
  cursor: pointer;
  place-items: center;
  background-color: ${({ grey, hasExpiring }) => hasExpiring ? colors.alert.orange300 : grey ? colors.gray.gray10 : colors.brand.red};
`;

export const Ean = styled.div`
  overflow: hidden;
  color: ${colors.gray.gray40};
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px;
  line-height: 140%;
`;

export const CommissionFlag = styled.span`
  width: fit-content;
  padding: 4px;

  border-radius: 6px;
  background-color: ${colors.grey10};

  font-size: 11px;
  font-weight: 700;
  color: ${colors.gray.gray50};
`;

export const SelectEmbalagem = styled.p`
  text-align: center;
  font-size: 11px;
  margin-bottom: 0px;
  flex: 1;
  margin: 0px;
  white-space: pre;
  font-weight: 400;
  color: ${colors.gray.gray60};
`;

export const Container = styled.div<{ direction?: Directions }>`
  display: flex;
  gap: ${(i) => (i.direction === "grid" ? "0px" : "12px")};
  flex-direction: ${(i) => (i.direction === "grid" ? "column" : "row")};
`;

export const ContentImage = styled(Link)<{ direction?: Directions }>``

export const PaddingImage = styled.div<{ direction?: Directions }>`
  position: relative;
  padding: ${(i) => (i.direction === "grid" ? "15%" : "0px")};
  padding-bottom: 5px;
`

export const DescProduct = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  line-height: 16px;
  min-height: 40px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  padding: 0px;
  margin-bottom: 10px;
  color: ${colors.gray.gray60};
`;

export const BoxQtdEmbalagem = styled.div`
  padding: 8px 10px;
  margin-bottom: 10px;
  border-radius: 0px 6px 6px;
  background-color: ${colors.gray.gray10};
`;

export const QtdEmbalagem = styled.div`
  white-space: nowrap;
  font-weight: 400;
  font-size: 11px;
  text-align: left;
  line-height: 120%;
  margin-bottom: 0px;
  color: ${colors.gray.gray60};
`;

export const BoxEmbalagem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  gap: 5px;
  -webkit-box-align: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding-top: 7px;
  border-radius: 6px 6px 0px 0px;
  cursor: pointer;
  border-top: 2px solid ${colors.gray.gray50};
  background-color: ${colors.gray.gray10};
`;

export const BoxLabel = styled.p`
  color: ${colors.gray.gray50};
  text-align: center;
  font-size: 9px;
  font-weight: 500;
  margin: 0px;
  line-height: normal;
`;

export const Price = styled.p`
  margin: 0px;
  display: flex;
  font-weight: 700;
  line-height: normal;
  font-size: 16px;
  text-align: left;
  color: ${colors.gray.gray60};
`;

export const UnitPrice = styled.p`
  margin: 0px;
  color: ${colors.grey3};
  font-size: 12px;
  line-height: normal;
  text-align: left;
  font-weight: 400;
  line-height: 140%;
`;

export const Underline = styled.p`
  margin: 0px;
  display: flex;
  line-height: normal;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: line-through;
  color: ${colors.gray.gray40};
`;

export const Line = styled.div`
  width: auto;
  flex: auto;
  height: 1px;
  background-color: ${colors.gray.gray10};
`;

export const LabelSelectEmbalagem = styled.div`
  gap: 5px;
  line-height: 10px;
  margin-bottom: 5px;
  display: flex;
  grid-column: auto;
  align-items: center;
`;

export const ContentChevron = styled.div`
  line-height: 1px;
  margin-bottom: 2px;
  text-align: center;
`;

export const AddList = styled.div`
  top: 0px;
  right: 0px;
  position: absolute;
`;

export const TimeContent = styled.div`
  top: 0px;
  left: 0px;
  z-index: 9;
  overflow: hidden;
  position: absolute;
  border-radius: 6px;
  border: 1px solid ${colors.brand.red};
`;

export const Time = styled.div`
  display: inline-flex;
  justify-content: center;
  background: ${colors.brand.red};
  align-items: center;
  gap: 4px;
  padding: 4px;
`;

export const TimerText = styled.div`
  font-size: 11px;
  font-weight: 700;
  line-height: 9px;
  color: ${colors.brand.white};
  text-align: center;
`;

export const TimeLabel = styled.div`
  font-size: 11px;
  font-weight: 700;
  padding: 4px 0px;
  text-align: center;
  background: ${colors.brand.white};
`;
