import * as S from "./styles";
import { useCallback } from "react";
import { Modal } from "@/components";
import VersionHistory from "@/constants/version-history.json"

interface ModalVersionHistoryProps {
  isOpenModal: boolean;
  setModalState: (state: boolean) => void;
}


const ModalVersionHistory = ({ isOpenModal, setModalState }: ModalVersionHistoryProps) => { 

  const formattedDate = useCallback(() => {
    const date = new Date();
    const day = date.getDate();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const today = `${day}/${month}/${year}`;
    
    return VersionHistory.date === today ? `Hoje às ${VersionHistory.time}` : `${VersionHistory.date} | ${VersionHistory.time}`
  }, [])

  const categoryIcon = (category: string): JSX.Element => {
    const CATEGORY_ICON: {[key: string]: JSX.Element} = {
      'Novidade': <S.NewFeatureIcon size={12}></S.NewFeatureIcon>,
      'Melhoria': <S.ImprovementIcon size={12}></S.ImprovementIcon>,
      'Correção': <S.BugIcon size={12}></S.BugIcon>
    }
    return CATEGORY_ICON[category]
  }

  return (
    <Modal
        isLoading
        id="version-history"
        direction="bottom"
        isOpen={isOpenModal}
        closeModal={setModalState}
      >
        <S.VersionModal className="version-modal">
          <S.ModalHeader className="modal-header">
            <S.CloseIcon size={24} onClick={() => setModalState(false)}/>
            <S.ModalTitle>O que teve de novidades nessa versão?</S.ModalTitle>
            <S.Date>
              <S.ClockIcon size={16}/>
              {formattedDate()}
            </S.Date>
          </S.ModalHeader>

          <S.VersionLine className="version-line">
            <S.TransparentBlock className="transparent">
              <S.VersionLabel onClick={() => {
                navigator.serviceWorker?.getRegistrations().then(registrations => {
                  registrations.forEach(registration => {
                    registration.unregister().then(() => {
                      document.cookie = 'Clear-Site-Data: "cache", "cookies", "storage", "executionContexts"';
                      window.location.reload();
                    }).catch(error => {
                      console.error('Service worker unregistration failed:', error);
                    });
                  });
                }).catch(error => {
                  console.error('Service worker registration retrieval failed:', error);
                });
              }}>Versão &#8226; {VersionHistory.version}</S.VersionLabel>
            </S.TransparentBlock>
          </S.VersionLine>

          <S.VersionList className="version-list">
            <S.Cards>
              {VersionHistory.changes.map((item, index) => (
                <S.VersionCard className="version-card" key={index}>
                  <S.Timeline className="timeline">
                    <S.Circle className="circle"></S.Circle>
                  </S.Timeline>
                  <S.VersionInfo className="version-info">
                    <S.VersionCategory $category={item.category} className="version-category">
                      {categoryIcon(item.category)}
                      {item.category}
                    </S.VersionCategory>
                    <S.VersionDescription className="version-description">{item.description}</S.VersionDescription>
                  </S.VersionInfo>
                </S.VersionCard>
              ))}
            </S.Cards>
          </S.VersionList>
        </S.VersionModal>
      </Modal>
  )
}

export default ModalVersionHistory