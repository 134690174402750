import * as S from './styles';
import { getOnStorage } from '@/shared';
import { useDbFunctions } from '@/talons';
import { useNavigate } from 'react-router-dom';
import { copy } from '@/shared/commonFunctions';
import { ECOMMERCE } from '@/constants/systemRoutes';
import { useCart, useClient, useGlobals } from '@/hooks';
import { ClientProps, TabCodes, TabParams } from '@/types';
import { ListCompany, Modal, TabSlider, ToolTip } from '@/components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface Props {
  children: any;
}

const ModalListCompanies = ({ children }: Props) => {
  const isLoading = useRef(false);
  const navigation = useNavigate();
  const { getOnDB } = useDbFunctions();
  const { setPaymentCode } = useGlobals();
  const [filter, setFilter] = useState('');
  const { createEmptyCart, clearCart } = useCart();
  const [isOpenedModal, openModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  // const [clients, setClients] = useState([] as ClientProps[]);
  const [selectedTab, setSelectedTab] = useState('all' as TabCodes);
  const { allClients, setAllClients, setSelectedClient } = useClient();
  const [selectedClient, setLocalClient] = useState({} as ClientProps | undefined);

  const loadClients = useCallback(async () => {
    const list = await getOnDB('clientes');
    const currClient = getOnStorage('selected_client');
    
    if (currClient) setLocalClient(currClient);
    setAllClients(list || []);

    if (!list?.length) {
      setTimeout(() => loadClients(), 500);
      return
    }

    isLoading.current = false;
  }, [getOnDB, setAllClients]);

  useEffect(() => {
    if (allClients.length || isLoading.current) return;

    isLoading.current = true;
    loadClients();
  }, [loadClients, allClients, isLoading]);

  const listTabs = useMemo((): TabParams[] => {
    return [
      { code: 'all', title: 'Todos', notification: allClients.length },
      // {
      //   code: "noPurchases",
      //   title: "Sem comprar",
      //   notification: allClients.filter((c) => c.daysWithoutPurchase).length,
      // },
      // {
      //   code: "titles",
      //   title: "Títulos",
      //   notification: allClients.filter((c) => c.notifications).length,
      // },
      // {
      //   code: "suspended",
      //   title: "Suspensos",
      //   notification: allClients.filter((c) => c.suspended).length,
      // },
    ] as TabParams[];
  }, [allClients]);

  const listClients = useMemo((): ClientProps[] => {
    if (filter) {
      return allClients.filter((c) => {
        const toFilter = copy(c);

        delete toFilter.progress1;
        delete toFilter.progress2;
        delete toFilter.progress3;
        delete toFilter.progress4;
        delete toFilter.COD_PAGAMENTO;

        return Object.values(toFilter).toString().toLowerCase().includes(filter.toLocaleLowerCase());
      });
    }

    if (selectedTab === 'all') return allClients;

    if (selectedTab === 'noPurchases') {
      return allClients.filter((c) => c.daysWithoutPurchase);
    }

    if (selectedTab === 'suspended') {
      return allClients.filter((c) => c.suspended);
    }

    return allClients.filter((c) => c.notifications);
  }, [filter, allClients, selectedTab]);

  const goTo = async () => {
    if (!selectedClient) return;

    setSelectedClient(selectedClient);
    clearCart();
    createEmptyCart({ client: selectedClient });
    // setListPaymentCode(selectedClient?.COD_PAGAMENTO);
    setPaymentCode(selectedClient?.COD_CONDICAO_PADRAO || selectedClient?.COD_PAGAMENTO?.[0]?.cod_condicao || 14);

    navigation(ECOMMERCE.link);
    openModal(false);
  };

  const handleDisabled = useCallback(async () => {
    if (!isOpenedModal) {
      setShowTooltip(false);
      return;
    }

    const dependencies = await Promise.all([
      getOnDB('produtos'),
      getOnDB('categorias'),
      getOnDB('estoque'),
      getOnDB('marcas'),
      getOnDB('percentualFornecedor'),
      getOnDB('precos'),
      getOnDB('substituicaotributaria'),
    ]);

    const isFilled = dependencies.reduce((a, i) => (a ? !!i?.length : a), true);
    setShowTooltip(!isFilled);
  }, [getOnDB, isOpenedModal]);

  useEffect(() => {
    handleDisabled();
  }, [isOpenedModal, handleDisabled]);

  const isEnabled = useMemo(() => {
    return !!selectedClient?.COD_CLIENTE;
  }, [selectedClient?.COD_CLIENTE]);

  return (
    <>
      <S.ButtonSpan onClick={() => openModal(true)} data-test="modal-companies-button-open">
        {children}
      </S.ButtonSpan>

      <Modal fullHeight id="list-companies" isOpen={isOpenedModal} closeModal={openModal}>
        <S.ModalTitle>Para iniciar, selecione um cliente</S.ModalTitle>

        <S.InputContent>
          <S.SearchIcon $isgray size={24} />

          <S.InputModal
            value={filter}
            placeholder="Busque por cliente"
            data-test="modal-list-companies-input-search"
            onChange={(event: any) => setFilter(event.target.value.trim())}
          />
        </S.InputContent>

        <S.ContentTabSlider>
          <TabSlider lightMode listTabs={listTabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </S.ContentTabSlider>

        <S.ContentListCompany>
          <ListCompany clients={listClients} selectedClient={selectedClient} setLocalClient={setLocalClient} />
        </S.ContentListCompany>

        {isEnabled && (
          <S.BottomContent>
            <ToolTip
              direction="top"
              show={showTooltip}
              displayMode="default"
              text="Por favor, sincronize a aplicação para liberar o acesso."
            >
              <S.ButtonAccess onClick={goTo} disabled={showTooltip} data-test="modal-companies-button-go-in">
                Entrar com esse cliente
              </S.ButtonAccess>
            </ToolTip>
          </S.BottomContent>
        )}
      </Modal>
    </>
  );
};

export default ModalListCompanies;
