import { camelCase } from 'lodash';
import { useCart } from '@/hooks/CartContext';
import { useCallback, useState } from 'react';
import { sem_imagem as semImg } from '@/constants';
import { getOnStorage, setOnStorage } from '@/shared';
import { useDbFunctions, Tables } from '../dbFunctions/useDBFunctions';
import { useFetchAxios, useGlobals, useUserContext } from '@/hooks';
import { ClientProps, ProductProps, CategoryProps, DataSetsProps, CustomerDataProps, PromocaoClientProps } from '@/types';

type CatalogDictProps = Record<number, ProductProps>;
type CategoryDictProps = Record<number, CategoryProps>;

const updateProgress = (loaded: number, total: number, name: string) => {
  const percentual = (loaded / total) * 100;
  const progressBar = document.getElementById(`${name}-bar`) as HTMLElement;
  const progressText = document.getElementById(`${name}-text`) as HTMLElement;
  const isInfinity = percentual !== Infinity;

  if (progressBar) progressBar.style.width = `${percentual}%`;
  if (progressText) {
    progressText.textContent = isInfinity ? `${Math.round(percentual)}%` : 'Erro no Download';
  }
};

export const useDownloadFiles = () => {
  const { axiosCtx } = useFetchAxios();
  const { setCustomer } = useUserContext();
  const { setHasLoadedEstoque } = useCart();
  const [hasError, setError] = useState(false);
  const { setLastSync, getDate } = useGlobals();
  const [loading, setLoading] = useState(false);
  const [preparing, setPreparing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileName, setFileDownloading] = useState('');
  const [isOpenedModal, openModalSync] = useState(false);
  const { setOnDB, deleteDB, getOnDB } = useDbFunctions();
  const [noUpdate, setNoUpdate] = useState(false as Boolean);
  const [datasets, setDataSets] = useState([] as DataSetsProps[]);

  const setFileName = useCallback(() => {
    if (isSuccess) return;

    const listDataBase = getOnStorage('datasets');
    const ind = Math.floor(Math.random() * (listDataBase?.length || 0));
    let label = listDataBase?.[ind]?.DESC_ARQUIVO;
    if (label === fileName) {
      label = listDataBase?.[ind + 1]?.DESC_ARQUIVO || 'Baixando';
    }

    setFileDownloading(label || '');
    setTimeout(() => setFileName(), 3000);
  }, [isSuccess, fileName]);

  const loadFiles = useCallback(
    async (clearDB?: boolean) => {
      if (loading) return;

      setFileName();
      setLoading(true);
      setError(false);
      setIsSuccess(false);
      updateProgress(0, 0, '.');

      var totalSize = 0;
      var downloadedSize = 0;

      const fetchAndSaveJson = async (url: string, name: string, enableLog?: boolean): Promise<any> => {
        return new Promise(async (resolve, reject) => {
          await fetch(url)
            .then((resp: any) => {
              if (resp.status >= 300 || resp.status < 200) throw new Error('');

              var totalArchive = 0;
              var downloadedArchive = 0;

              totalSize += Number(resp.headers.get('content-length'));
              totalArchive += Number(resp.headers.get('content-length'));

              let chunks: any[] = [];
              const reader = resp.body.getReader();
              const decoder = new TextDecoder('utf-8');

              const readChunk = () => {
                reader
                  .read()
                  .then(async ({ done, value }: any) => {
                    try {
                      if (done) {
                        const json = JSON.parse(chunks.join(''));
                        let data = json?.catalogo || json?.preco || json;

                        if (name === 'imagens') {
                          data = data.reduce(
                            (acc: any, item: any) => ({
                              ...acc,
                              [item.COD_PRODUTO]: item.imagem,
                            }),
                            {}
                          );
                        }

                        if (typeof data === 'string') data = JSON.parse(data);
                        resolve({ data, name, enableLog });
                      }

                      downloadedSize += value?.byteLength || 1;
                      downloadedArchive += value?.byteLength || 1;
                      updateProgress(downloadedArchive, totalArchive, name);
                      updateProgress(downloadedSize, totalSize, 'progress');
                      if (value?.byteLength) {
                        chunks.push(decoder.decode(value, { stream: true }));
                        readChunk();
                      }
                    } catch (error) {
                      console.log('Erro durante a leitura do stream:', error);
                    }
                  })
                  .catch((error: any) => {
                    setError(true);
                    console.error('Erro no download do stream:', error);
                  });
              };

              readChunk();
            })
            .catch((error) => {
              if (!['precoPromocaoCliente', 'indicadores'].includes(name)) setError(true);
              console.error('Erro durante o download:', error);
              return resolve(true)

              // reject(error);
            });
        });
      };

      let listToLoad = [] as DataSetsProps[];
      const listDataBase = getOnStorage('new_data_sets');
      const actualDataBase = getOnStorage('datasets');

      if (!actualDataBase) {
        listToLoad = listDataBase || [];
      } else {
        for (const element of listDataBase || []) {
          if (!actualDataBase?.find((i) => i.URL_S3 === element.URL_S3)) listToLoad?.push(element)
          
          else {
            const fileType = camelCase(element.TIPO_ARQUIVO)

            const modifyers: Record<string, string> = {
              preco: 'precos',
              marcas: 'marcas',
              estoque: 'estoque',
              imagens: 'imagens',
              cliente: 'clientes',
              catalogo: 'catalogo',
              categoria: 'categorias',
              janela_entrega: 'janelaEntrega',
              parametros_aws: 'parametrosAws',
              parametro_preco_base: 'parametroPrecoBase',
              codRegimeTributario: 'substituicaotributaria',
              percentual_fornecedor: 'percentualFornecedor',
              cod_regime_tributario: 'substituicaotributaria',
              restricaoProdutoCliente: 'productRestrictionClient',
            };
            
            const table = modifyers[fileType];
            const item = table ? await getOnDB(table as Tables) : null
            if (!item?.length && table) listToLoad.push(element)
          }
        }
      }

      const listToUpdate = listToLoad;

      console.log(listToUpdate);
      

      if (listToUpdate?.length === 0 || !listToUpdate) {
        setNoUpdate(true);
      };

      setDataSets(listDataBase || []);

      const loads = listToUpdate?.map(({ TIPO_ARQUIVO, URL_S3 }) => fetchAndSaveJson(URL_S3, TIPO_ARQUIVO, true)) || [];

      const list = await Promise.all(loads)
        .then((res) => {
          return res.reduce((acc, item) => ({ ...acc, [item.name]: item }), {});
        })
        .catch(() => { });

      const precos = list?.preco?.data || [];
      const marcas = list?.marcas?.data || [];
      const imagens = list?.imagens?.data || [];
      const estoque = list?.estoque?.data || [];
      const catalogo = list?.catalogo?.data || [];
      const categorias = list?.categoria?.data || [];
      const saldoFlex = list?.saldo_flex?.data || [];
      const janelaEntrega = list?.janela_entrega?.data;
      const indicadores = list?.indicadores?.data || [];
      const pagebuilder = list?.pagebuilder?.data || [];
      const parametrosAws = list?.parametros_aws?.data || [];
      const clientes = (list?.cliente?.data as ClientProps[])?.sort((a, b) => {
        if (a.NM_FANTASIA > b.NM_FANTASIA) return 1
        if (a.NM_FANTASIA < b.NM_FANTASIA) return -1
        return 0
      }).map(c => {
        c.COD_SEGMENTO = JSON.parse(c.COD_SEGMENTO.toString())
        c.UNILEVER = c.FL_UNILEVER === 'True'
        return c
      }) || [];

      const recomendacaoBigData = list?.recomendacaoBigData?.data || [];
      const parametroPrecoBase = list?.parametro_preco_base?.data || [];
      const precoPromocaoCliente = list?.precoPromocaoCliente?.data || [];
      const percentualFornecedor = list?.percentual_fornecedor?.data || [];
      const substituicaotributaria = list?.cod_regime_tributario?.data || [];
      const restricaoProdutoCliente = list?.restricaoProdutoCliente?.data || [];

      const objProduct = {} as CatalogDictProps;
      const objCategory = {} as CategoryDictProps;

      setPreparing(true);

      const promocaoCliente = precoPromocaoCliente.reduce((acc: any, p: PromocaoClientProps) => {
        if (acc[p.COD_CLIENTE]) {
          acc[p.COD_CLIENTE][p.COD_PRODUTO] = p
        } else {
          acc[p.COD_CLIENTE] = { [p.COD_PRODUTO]: p }
        }
        return acc
      }, {});

      const products = catalogo
        .map((item: any) => {
          if (item.COD_PRODUTO === 1185802) item.FL_UNILEVER = "True"
          const PRECO = precos?.filter((i: any) => `${i.COD_PRODUTO}` === `${item.COD_PRODUTO}`);

          const EMBALAGEM = item?.EMBALAGEM?.map((i: any) => {
            if (i?.QTD_MULTIPLO_VENDA > 1) {
              return { ...i, SG_EMBALAGEM: "PK" }
            }

            return { ...i }
          });

          const IMAGEM = imagens?.[item?.COD_PRODUTO] || imagens['sem_imagem'] || semImg;

          return { ...item, PRECO, IMAGEM, EMBALAGEM, UNILEVER: item.FL_UNILEVER === 'True' };
        })
        ?.filter((item: any) => !!item?.PRECO?.length);

      if ((listToUpdate?.length === listDataBase?.length) && (listToUpdate?.length !== 0) && (clearDB)) await deleteDB();

      if (categorias?.length > 0 && products?.length > 0) {
        categorias?.forEach((item: CategoryProps) => {
          if (!objCategory[item?.COD_CATEGORIA]) {
            objCategory[item?.COD_CATEGORIA] = {
              ...item,
            };
          }
        });

        products?.forEach((item: ProductProps) => {
          if (!objProduct[item?.COD_PRODUTO]) {
            objProduct[item?.COD_PRODUTO] = {
              ...item,
            };
          }
        });
      }

      if (marcas?.length) await setOnDB(marcas, 'marcas');
      if (precos?.length) await setOnDB(precos, 'precos');
      if (imagens?.length) await setOnDB(imagens, 'imagens');
      if (estoque?.length) await setOnDB(estoque, 'estoque');
      if (catalogo?.length) await setOnDB(catalogo, 'catalogo');
      if (clientes?.length) await setOnDB(clientes, 'clientes');
      if (products?.length) await setOnDB(products, 'produtos');
      if (saldoFlex?.length) await setOnDB(saldoFlex, 'saldoFlex');
      if (categorias?.length) await setOnDB(categorias, 'categorias');
      if (pagebuilder?.length) await setOnDB(pagebuilder, 'pagebuilder');
      if (indicadores?.length) await setOnDB(indicadores, 'indicadores');
      if (parametrosAws?.length) await setOnDB(parametrosAws, 'parametrosAws');
      if (janelaEntrega?.length) await setOnDB(janelaEntrega, 'janelaEntrega');
      if (parametroPrecoBase?.length) await setOnDB(parametroPrecoBase, 'parametroPrecoBase');
      if (recomendacaoBigData?.length) await setOnDB(recomendacaoBigData, 'recomendacaoBigData');
      if (percentualFornecedor?.length) await setOnDB(percentualFornecedor, 'percentualFornecedor');
      if (substituicaotributaria?.length) await setOnDB(substituicaotributaria, 'substituicaotributaria');
      if (objProduct && Object.keys(objProduct).length) await setOnDB(objProduct, 'dicionario_produtos');
      if (objCategory && Object.keys(objCategory).length) await setOnDB(objCategory, 'dicionario_categorias');
      if (restricaoProdutoCliente?.length) await setOnDB(restricaoProdutoCliente, 'productRestrictionClient');
      if (precoPromocaoCliente && Object.keys(promocaoCliente).length) await setOnDB(promocaoCliente, 'precoPromocaoCliente');

      setPreparing(false);
      setIsSuccess(true);
      setLoading(false);
      const rcaCustomer = getOnStorage('rca_usuario');
      setCustomer(rcaCustomer as CustomerDataProps);
      setHasLoadedEstoque(false);
      return list
    },
    [loading, setFileName, deleteDB, setOnDB, setCustomer, setHasLoadedEstoque, getOnDB]
  );

  const downloadLinks = useCallback(async () => {
    setPreparing(true);
    setError(false);
    setIsSuccess(false);
    setLoading(true);

    const rcaInfos = getOnStorage('rca_usuario');

    const resp: any = await axiosCtx.get(`/getdataset/${rcaInfos?.codPessoa}`).catch(() => 'error');

    if (resp === 'error') {
      setPreparing(false);
      setError(true);
      setLoading(false);
      return;
    }

    const [representante] = resp?.data?.representante || [];

    setOnStorage('new_data_sets', representante?.link_Dataset || []);

    const data = await loadFiles(true);
    const formattedDate = getDate();
    setLastSync(formattedDate);

    return data
  }, [loadFiles, setLastSync, getDate, axiosCtx]);

  return {
    loading,
    hasError,
    fileName,
    datasets,
    preparing,
    isSuccess,
    isOpenedModal,

    setError,
    loadFiles,
    downloadLinks,
    openModalSync,

    noUpdate
  };
};
