import * as S from "./styles";
import { IoIosAdd } from "react-icons/io";
import { formatCurrency } from "@/shared";
import { Counter, ToolTip } from "@/components";
import { useCallback, useMemo, useState } from "react";

import {
  ProductProps,
  PackagesTypesProps,
  PackagingProductProps,
} from "@/types";

import {
  useCart,
  useGlobals,
  useUserContext,
  useDiscountFlex,
  useDiscountAmountFlex,
  useClient,
  useStock,
} from "@/hooks";

const getOriginalValue = (value: number, percent: number) => {
  const porcentagemDecimal = percent / 100;
  const fatorMultiplicador = 1 + porcentagemDecimal;
  return value / fatorMultiplicador;
};
interface Props {
  id: string;
  label?: string;
  hasDiscount?: boolean;
  unavailabel?: boolean;
  product: ProductProps;
  pack: PackagesTypesProps;
  packQty: number;
  background?: "light" | "white";
}

const AddToCart = ({
  id,
  pack,
  packQty,
  label,
  product,
  background,
  unavailabel,
  hasDiscount,
}: Props) => {
  const { paymentCode } = useGlobals();
  const { rcaSaldoFlex } = useUserContext();
  const { getDiscountProduct } = useDiscountFlex();
  const { selectedClient } = useClient();
  const { stockLeft } = useStock();
  const { addProductToCart, getLimit, cartDetails } = useCart();
  const [isOpenTooltip, setOpenTooltip] = useState(false);
  const { discountAmountClient: discountAmount } = useDiscountAmountFlex();

  const packing = useMemo(
    () => {
      return product?.EMBALAGEM.find((e: PackagingProductProps) => {

        if (pack === "PK") {
          return e?.SG_EMBALAGEM === pack && packQty === e?.QTD_MULTIPLO_VENDA;
        }
        return e?.SG_EMBALAGEM === pack && e?.QTD_EMBALAGEM === packQty;
      })
    },
    [product?.EMBALAGEM, pack, packQty]
  ) as PackagingProductProps;


  const quantity = useMemo(
    () => {
      if (cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0) {

        return cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find((i) => { 

          const [emb, qtd] = i?.option_uid?.split("_") as [PackagesTypesProps, string];

          if (packing?.QTD_MULTIPLO_VENDA > 1 && emb === "PK") {

            return (i?.sku === product?.COD_PRODUTO &&
            packing?.SG_EMBALAGEM === emb &&
            qtd === packing?.QTD_MULTIPLO_VENDA?.toString());
          }

        return i?.sku === product?.COD_PRODUTO && packing?.SG_EMBALAGEM === emb && qtd === packing?.QTD_EMBALAGEM?.toString() })?.quantity || 0;
      } else {
        return 0;
      }
    },
    [cartDetails, selectedClient?.COD_CLIENTE, packing?.QTD_MULTIPLO_VENDA, packing?.SG_EMBALAGEM, packing?.QTD_EMBALAGEM, product?.COD_PRODUTO]
  );

  const totalQuantity = useMemo(() => {
    let count = 0;
    cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.reduce((acc, currentValue) => {
      if (currentValue?.sku === product?.COD_PRODUTO) {
        const [, qtd] = currentValue?.option_uid?.split('_');

        const qty = Number(qtd);
        const val = currentValue?.quantity * qty;
        count = acc += val;
      }
      return acc;
    }, 0);
    return count;
  }, [cartDetails, product?.COD_PRODUTO, selectedClient?.COD_CLIENTE]);

  const isDisabled = useMemo(() => {
    const totalStock = getLimit(product?.COD_PRODUTO);
    const multiple = packing?.QTD_MULTIPLO_VENDA > 1 ? packing?.QTD_MULTIPLO_VENDA : packing.QTD_EMBALAGEM;
    const limit = totalStock - totalQuantity;
    
    return !Math.floor(limit/multiple)
  }, [getLimit, packing, product?.COD_PRODUTO, totalQuantity])

  const hasStock = useMemo(() => {
    const limit = getLimit(product?.COD_PRODUTO);
    const multiple = packing?.QTD_MULTIPLO_VENDA > 1;
    const stock = stockLeft?.[product?.COD_PRODUTO]?.stock_left;

    if (stockLeft?.[product?.COD_PRODUTO]) {
      if (stock && stock > 0) {
        if (!quantity) {
          if (multiple) return stock > 0 && (stock >= packing?.QTD_MULTIPLO_VENDA);
          return stock > 0 && (stock >= packing?.QTD_EMBALAGEM);
        } 
        if (multiple) return stock > 0 && (stock >= packing?.QTD_MULTIPLO_VENDA * quantity);
        return stock > 0 && (stock >= packing?.QTD_EMBALAGEM * quantity);
      }
      return false;
    }

    if (quantity) {
      if (multiple) {
        return limit > 0 && (limit >= packing?.QTD_MULTIPLO_VENDA * quantity);
      }
      return limit > 0 && (limit >= packing?.QTD_EMBALAGEM * quantity);
    }
   
    if (multiple) {
      return limit > 0 && (limit >= packing?.QTD_MULTIPLO_VENDA);
    }
    return limit > 0 && (limit >= packing?.QTD_EMBALAGEM);
  }, [getLimit, product?.COD_PRODUTO, quantity, stockLeft, packing]);

  const amountProduct = useMemo(
    () => {
      let packOption = packing?.SG_EMBALAGEM;
      let packQtd = packing?.QTD_EMBALAGEM;

      if (packing?.QTD_MULTIPLO_VENDA > 1) {
        packQtd = packing?.QTD_MULTIPLO_VENDA;
      }
      return getDiscountProduct({
        package: packOption,
        quantity: packQtd,
        productId: product.COD_PRODUTO,
      })
    },
    [packing, product, getDiscountProduct]
  );

  const limitFlex = useMemo(() => {
    if (!amountProduct?.percentual) return 0;

    const discountPrice = amountProduct?.discountPrice?.[`${paymentCode}`] || 0;
    const originalValue =
      getOriginalValue(discountPrice, amountProduct?.percentual || 0) || 0;
    const diffPrice = originalValue - discountPrice;
    return (
      Math.trunc(((rcaSaldoFlex || 0) + discountAmount) / diffPrice) + quantity
    );
  }, [quantity, paymentCode, rcaSaldoFlex, amountProduct, discountAmount]);

  const addProduct = useCallback(() => {
    if (!!amountProduct?.percentual && quantity >= limitFlex) {
      setOpenTooltip(true);
      setTimeout(() => setOpenTooltip(false), 5000);
      return;
    }

    if (product?.EMBALAGEM?.length) {
      // console.log('embalagem produto: ', product?.EMBALAGEM);

      if (packing?.QTD_MULTIPLO_VENDA > 1 && packing?.SG_EMBALAGEM === "PK") {

        return addProductToCart({
          quantity: 1,
          sku: product?.COD_PRODUTO,
          category_id: product.COD_CATEGORIA,
          option_uid: `${packing?.SG_EMBALAGEM}_${packing?.QTD_MULTIPLO_VENDA}`,
          uid: `${product?.COD_PRODUTO}-${packing?.SG_EMBALAGEM}-${packing?.QTD_MULTIPLO_VENDA}`
        })
      }
      addProductToCart({
        quantity: 1,
        sku: product?.COD_PRODUTO,
        option_uid: `${packing?.SG_EMBALAGEM}_${packing?.QTD_EMBALAGEM}`,
        category_id: product?.COD_CATEGORIA,
        uid: `${product?.COD_PRODUTO}-${packing?.SG_EMBALAGEM}-${packing?.QTD_EMBALAGEM}`,
      });
    } else {
      window.alert("Produto sem estoque disponível nesta embalagem");
    }
  }, [
    addProductToCart,
    product,
    packing,
    // pack,
    limitFlex,
    quantity,
    amountProduct?.percentual,
  ]);

  const tooltipMessage = useMemo(() => {
    return `A adição deste item ultrapassará o total do Saldo Flex: ${formatCurrency(
      {
        value: (rcaSaldoFlex || 0) + discountAmount,
      }
    )}`;
  }, [rcaSaldoFlex, discountAmount]);

  return (
    <S.AddToCartWrapper>
      {unavailabel || !hasStock ? (
        <S.Unavailabel>Indisponível</S.Unavailabel>
      ) : !quantity ? (
        <ToolTip
          direction="top"
          show={isOpenTooltip}
          displayMode="default"
          text={tooltipMessage}
        >
          <S.AddToCartButton
            disabled={isDisabled}
            onClick={addProduct}
            data-test={`add-to-cart-component-${id}`}
          >
            <IoIosAdd />
            {label || "Adicionar ao carrinho"}
          </S.AddToCartButton>
        </ToolTip>
      ) : (
        <S.CounterWrapper>
          <Counter
            id={`${id}`}
            pack={packing}
            product={product}
            limitFlex={limitFlex}
            discountProduct={amountProduct}
            background={background || "white"}
          />
        </S.CounterWrapper>
      )}
    </S.AddToCartWrapper>
  );
};

export default AddToCart;
