import React from 'react';
import * as S from './styles';
import Modal from '../modal/Modal';
import { ClientProps } from '@/types';
import SyncHeader from '@/components/modal-order-status/syncHeader';
import { OrderItemsProps } from '@/talons/orderStatusPage/useOrderStatusPage';
import useOrderStatusModal from '@/talons/orderStatusModal/useOrderStatusModal';
import { OrderStatusHeader, OrderStatusCard, CartOrderStatusCard, UpdateOrderStatus } from '@/components';

interface ModalProps {
  order: OrderItemsProps;
  isOpen: boolean;
  client: ClientProps;
  closeModal: (status: string) => void;
  clientRefNumber: string;
  clientName: string;
  clientCnpj: string; 
}

const OrderStatusModal = ({
    order,
    clientName,
    clientRefNumber,
    clientCnpj,
    client,
    isOpen = true,
    closeModal
  }: ModalProps) => {

  const { handleReprocessOrder, handleUpdateOrder } = useOrderStatusModal({ order, companyData: {
    clientName,
    clientRefNumber,
    clientCnpj,
  } });

  console.log('order order', order);

  return (
    <Modal id={`modal-order-status-${order?.orderNumber}`}
      direction={"right"}
      isOpen={isOpen}
      closeModal={() => closeModal(order?.orderNumber)}
    >
      <S.Title>
        Ações pendentes neste pedido
      </S.Title>
      <S.Divider />
      <S.Content>
        {order?.status === "indisponivel" && (
          <SyncHeader syncOrder={handleReprocessOrder} />
        )}
        <S.Card>
          <S.InnerContainer>
            <OrderStatusHeader
              client={client}
              clientName={clientName}
              clientRefNumber={clientRefNumber}
            />
          </S.InnerContainer>
          <OrderStatusCard
            isLast={true}
            order={order}
            status={order?.status}
            // showModal={false}
          />
        </S.Card>
      </S.Content>
      {order?.actionsPending?.length && (
        <React.Fragment>
          <S.Subtitle>
            {order?.status !== "reprocessar" && order?.status !== "rejeitado" ? 'Produtos ' : 'Ações pendentes ' } {`(${order?.actionsPending?.length})`}
          </S.Subtitle>
          {order?.actionsPending?.map((item, index) => (
            <CartOrderStatusCard
              key={`actions-pending-modal-card-${index}`}
              item={item}
              isFirst={index === 0}
            />
          ))}
          <UpdateOrderStatus
            paymentCondition={Number(order?.paymentTerms)}
            grandTotal={order?.total}
            quantity={Number(order?.quantity)}
            canProcessOrder={order?.status === "reprocessar" || order?.status === "rejeitado"}
            processOrder={() => handleUpdateOrder({ pendingActions: order?.actionsPending })}
          />
        </React.Fragment>
        )}
      
    </Modal>
  )
}

export default OrderStatusModal;
